import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

export const useSearchParams = <T>(key: string): T => {
  const { query } = useRouter();

  const [result, setResult] = useState<T>();

  useEffect(() => {
    setResult(query[key] as T);
  }, [query, key]);

  return result!;
};
