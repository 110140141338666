import { AppFragment, LeafFragment } from 'enums/path-fragment';
import { NextRouter } from 'next/router';
import { Category } from 'types/categoryEnum';
import { isClientSide, isNotEmpty } from 'utility/functions';
import { HintCategory } from '../../../lib/api/searchResponseApi';
import { Hint } from '../searchTypes';

const isSSR = isClientSide() ? false : true;

interface Search {
  selectedSearch?: Hint;
  router: NextRouter;
  query: string;
  category: Category;
}

export const handleSearch = ({ selectedSearch, router, query, category }: Search): void => {
  if (isSSR) return;

  let redirectUrl = '';
  if (selectedSearch && !isCategory(selectedSearch!)) {
    redirectUrl = `${process.env.NEXT_PUBLIC_BASE_URL}/${getPathByCategory(category)}/gioco/${selectedSearch?.slug}`;
  } else {
    const params = new URLSearchParams();
    params.set('q', selectedSearch?.name ?? query);
    if (isNotEmpty(selectedSearch)) {
      params.set('category', `${isCategory(selectedSearch!)}`);
    }
    redirectUrl = `${process.env.NEXT_PUBLIC_BASE_URL}/${getPathByCategory(category)}/${
      LeafFragment.Search
    }?${params.toString()}`;
  }
  document.location.replace(redirectUrl);
};

export function isCategory(result: Hint): result is HintCategory {
  return result?.type === 'category';
}

export function getPathByCategory(category: Category): AppFragment {
  switch (category) {
    case Category.Slot:
      return AppFragment.SlotTop;
    case Category.Casino:
      return AppFragment.Casino;
    case Category.CardGame:
      return AppFragment.CardGame;
    case Category.Sport:
      return AppFragment.Sport;
  }
}
