import { cloneElement, useEffect, useRef } from 'react';
import styles from './SearchInputText.module.scss';

export interface SearchInputTextProps
  extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  trailingElement?: JSX.Element;
  leadingElement?: JSX.Element;
  isMenuOpened: boolean;
}

export const SearchInputText = ({ trailingElement, leadingElement, isMenuOpened, ...props }: SearchInputTextProps) => {
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (isMenuOpened) {
      inputRef.current?.focus();
    }
  }, [isMenuOpened]);

  return (
    <div className={styles.container}>
      <label htmlFor="search" className={styles.searchLabel}>
        Search:{' '}
      </label>
      {leadingElement &&
        cloneElement(leadingElement, {
          className: `${leadingElement?.props.className} ${styles.leading}`,
        })}
      <input
        {...props}
        name="search"
        type="search"
        ref={inputRef}
        autoComplete="off"
        className={`${styles.input} ${leadingElement && styles.leading} ${trailingElement && styles.trailing}`}
        suppressHydrationWarning={true}
      />
      {trailingElement &&
        cloneElement(trailingElement, {
          className: `${trailingElement?.props.className} ${styles.trailing}`,
        })}
    </div>
  );
};
