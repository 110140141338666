import { shallowEqual } from 'fast-equals';
import { RootState } from 'lib/centralStore';
import { createSelector } from 'reselect';
import { SearchState } from './searchTypes';

const _self = (state: RootState) => state.search as SearchState;

export const selectMostSearchedLoading = () =>
  createSelector([(state: RootState) => _self(state)], (search) => search.isMostSearchedLoading);
export const selectMostSearched = () =>
  createSelector([(state: RootState) => _self(state)], (search) => search.mostSearched?.hints ?? [], {
    memoizeOptions: { resultEqualityCheck: shallowEqual },
  });

export const selectAutocompleteGameLoading = () =>
  createSelector([(state: RootState) => _self(state)], (search) => search.isAutocompleteGameLoading);
export const selectAutocompleteGame = () =>
  createSelector([(state: RootState) => _self(state)], (search) => search.autocompleteGame?.hints ?? [], {
    memoizeOptions: { resultEqualityCheck: shallowEqual },
  });
