import { shallowEqual } from 'fast-equals';
import { useTypedSelector } from 'lib/centralStore';
import { useMemo } from 'react';
import {
  selectAutocompleteGame,
  selectAutocompleteGameLoading,
  selectMostSearched,
  selectMostSearchedLoading,
} from './searchSelectors';

export const useMostSearched = () => {
  const isLoadingFactory = useMemo(selectMostSearchedLoading, []);
  const mostSearchedFactory = useMemo(selectMostSearched, []);

  return useTypedSelector((state) => {
    const isLoading = isLoadingFactory(state);
    const hints = mostSearchedFactory(state);
    return { isLoading, hints };
  }, shallowEqual);
};

export const useAutocompleteGame = () => {
  const isLoadingFactory = useMemo(selectAutocompleteGameLoading, []);
  const selectAutocompleteGameFactory = useMemo(selectAutocompleteGame, []);

  return useTypedSelector((state) => {
    const isLoading = isLoadingFactory(state);
    const hints = selectAutocompleteGameFactory(state);
    return { isLoading, hints };
  }, shallowEqual);
};
